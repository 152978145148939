import { useRouter } from 'next/router';
import { Title, Text, useMantineTheme } from '@mantine/core';
import Link from 'next/link';
import { LoginForm } from 'components';
import { NextPageWithLayoutType } from 'types';
import { trackAction, Actions } from 'utils/amplitude';

const CLAIM_FORM_LINK = 'https://forms.gle/FDJVqMfh7C4mvAbr7';

const Login: NextPageWithLayoutType = () => {
  const router = useRouter();

  const { redirect } = router.query;

  const theme = useMantineTheme();

  return (
    <div>
      <Title c="blue" mt="xl">
        Login
      </Title>
      <LoginForm
        onLogin={() => {
          if (redirect) {
            router.push(`/${redirect}`);
          } else {
            router.push('/');
          }
        }}
      />
      <Text
        mb={4}
        style={{
          fontSize: theme.fontSizes.sm,
        }}
      >
        Seen your activity listed on our marketplace? Claim your listing{' '}
        <Link href={CLAIM_FORM_LINK} onClick={() => trackAction(Actions.CLAIM_LISTING_SUPPLIER)}>
          here
        </Link>
      </Text>
      <Text style={{ fontSize: theme.fontSizes.sm }}>
        Don't have an account yet?{' '}
        <Link href="/register" onClick={() => trackAction(Actions.SUPPLIER_REGISTER)}>
          Register
        </Link>
      </Text>
      <Link href="/forgot-password" onClick={() => trackAction(Actions.SUPPLIER_FORGOT_PASSWORD)}>
        Forgot password?
      </Link>
    </div>
  );
};

Login.layout = 'public';

export default Login;
